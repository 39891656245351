import React, { useEffect, useMemo, useRef } from "react";
import { FormikProps } from "formik";
import { useSelector } from "react-redux";
import { Box, IconButton, MenuItem, Select, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomButton from "@next/components/custom-button";
import CheckIcon from "@mui/icons-material/Check";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { FormValues } from "./upload-quotation.form.types";
import GenericAvatar from "@next/components/generic-avatar/generic-avatar";
import { CustomTextField } from "@next/modules/analytics/components/analytic-graph-card/analytic-graph-card.styled";
import { QuotationLineItemTable } from "./quotation-line-items-table/quotation-line-items-table";
import { UploadQuotationFile } from "./upload-quotation-files/upload-quotation-files";
import { selectUploadQuotationAsBuyerLoading } from "../../redux/selectors";
import { CURRENCY_OPTIONS } from "@next/utils/countryList";
import { getCurrencySuffix } from "@next/utils/miscUtils";

const useStyles = makeStyles((theme) => ({
  leftContainer: {
    height: "100%",
    width: "40%",
  },
  rightContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    width: "60%",
    borderLeft: `1px solid ${theme.palette.divider}`,
    overflowY: "scroll",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    padding: theme.spacing(3),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  headerLeftContainer: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
  },
  footer: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    padding: theme.spacing(2),
    gap: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  footerRow: {
    display: "flex",
    width: "100%",
    gap: theme.spacing(2),
    alignItems: "center",
  },
  footerContainer: {
    flex: 1,
  },
}));

export const UploadQuotationFormBase: React.FC<
  FormikProps<FormValues> & {
    onClose: () => void;
    supplier_picture: string;
    pdf_parsing_result?: any;
  }
> = ({
  values,
  setFieldValue,
  submitForm,
  onClose,
  supplier_picture,
  errors,
  touched,
  pdf_parsing_result,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const loading = useSelector(selectUploadQuotationAsBuyerLoading);
  const scrollableRef = useRef(null);

  useEffect(() => {
    scrollableRef.current.scrollTop = 0;
  }, []);

  const totalPrice = useMemo(() => {
    const price = values.parts.reduce((acc, part) => {
      return acc + part.price * part.quantity;
    }, 0);
    return parseFloat(
      (Number(price) + Number(values.fixed_charge ? values.fixed_charge : 0)).toFixed(2)
    );
  }, [values.parts, values.fixed_charge]);

  useEffect(() => {
    setFieldValue(`total_price`, totalPrice);
  }, [totalPrice]);

  return (
    <>
      <Box className={classes.leftContainer}>
        <UploadQuotationFile quotationFiles={values.files} setFieldValue={setFieldValue} />
      </Box>
      <Box className={classes.rightContainer} ref={scrollableRef}>
        <Box>
          <Box className={classes.header}>
            <Box className={classes.headerLeftContainer}>
              <GenericAvatar
                avatarProps={{
                  src: supplier_picture,
                  variant: "rounded",
                }}
              />

              <Typography variant="h6">{t("rfq:quotations:quoteAsBuyerModal:title")}</Typography>

              <Select
                labelId={`currency`}
                size="small"
                value={values.currency}
                sx={{
                  width: "150px",
                }}
                onChange={(e) => setFieldValue(`currency`, e.target.value)}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "center",
                  },
                }}
              >
                <MenuItem value={CURRENCY_OPTIONS.USD}>$ USD</MenuItem>
                <MenuItem value={CURRENCY_OPTIONS.CAD}>$ CAD</MenuItem>
                <MenuItem value={CURRENCY_OPTIONS.EUR}>€ EUR</MenuItem>
              </Select>
            </Box>
            <IconButton onClick={onClose}>
              <CloseIcon color="secondary" />
            </IconButton>
          </Box>
          <Box sx={{ height: "500px", px: "16px" }}>
            <QuotationLineItemTable
              setFieldValue={setFieldValue}
              pdf_parsing_result={pdf_parsing_result}
              parts={values.parts}
              currency={values.currency}
            />
          </Box>
        </Box>
        <Box className={classes.footer}>
          <Box
            className={classes.footerRow}
            style={{
              alignItems: touched.delay && !!errors.delay ? "flex-start" : "center",
            }}
          >
            <Box className={classes.footerContainer}>
              <Typography variant="body2">
                {t("rfq:quotations:quoteAsBuyerModal:leadTime")}
              </Typography>
            </Box>
            <Box className={classes.footerContainer}>
              <CustomTextField
                size="small"
                type="number"
                placeholder={t("rfq:quotations:quoteAsBuyerModal:fillFieldPlaceholder")}
                fullWidth
                value={values.delay}
                onChange={(e) => setFieldValue(`delay`, Math.max(0, parseInt(e.target.value)))}
              />
            </Box>
            <Box className={classes.footerContainer}>
              <Select
                labelId={`delay_unit`}
                size="small"
                value={values.delay_unit}
                fullWidth
                onChange={(e) => setFieldValue(`delay_unit`, e.target.value)}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "center",
                  },
                }}
              >
                <MenuItem value="d">{t("rfq:quotations:quoteAsBuyerModal:calenderDays")}</MenuItem>
                <MenuItem value="w">{t("rfq:quotations:quoteAsBuyerModal:weeks")}</MenuItem>
                <MenuItem value="m">{t("rfq:quotations:quoteAsBuyerModal:months")}</MenuItem>
              </Select>
            </Box>
          </Box>

          <Box className={classes.footerRow}>
            <Box className={classes.footerContainer}>
              <Typography variant="body2">
                {t("rfq:quotations:quoteAsBuyerModal:fixedFees")}
              </Typography>
            </Box>
            <Box className={classes.footerContainer}>
              <CustomTextField
                size="small"
                type="number"
                placeholder={t("rfq:quotations:quoteAsBuyerModal:fillFieldPlaceholder")}
                fullWidth
                value={values.fixed_charge}
                onChange={(e) => setFieldValue(`fixed_charge`, e.target.value)}
              />
            </Box>
            <Box
              className={classes.footerContainer}
              style={{
                marginTop: touched.fixed_charge && !!errors.fixed_charge ? "10px" : "0px",
              }}
            >
              <Typography variant="body2" fontWeight={700}>{`${getCurrencySuffix(
                values.currency
              )} ${values.currency}`}</Typography>
            </Box>
          </Box>

          <Box className={classes.footerRow}>
            <Box className={classes.footerContainer}>
              <Typography variant="body2">{t("rfq:quotations:quoteAsBuyerModal:total")}</Typography>
            </Box>
            <Box className={classes.footerContainer}>
              <CustomTextField
                size="small"
                type="number"
                placeholder={t("rfq:quotations:quoteAsBuyerModal:fillFieldPlaceholder")}
                value={values.total_price}
                onChange={(e) => setFieldValue(`total_price`, e.target.value)}
                fullWidth
              />
            </Box>
            <Box className={classes.footerContainer}>
              <Typography variant="body2" fontWeight={700}>{`${getCurrencySuffix(
                values.currency
              )} ${values.currency}`}</Typography>
            </Box>
          </Box>

          <Box
            sx={{
              width: "fit-content",
              alignSelf: "flex-end",
              display: "flex",
              gap: "16px",
              alignItems: "center",
            }}
          >
            <CustomButton variant="text" color="secondary" onClick={onClose}>
              <Typography variant="body2">{t("common:cancel")}</Typography>
            </CustomButton>
            <CustomButton
              loading={loading}
              autoFocus
              variant="contained"
              color="primary"
              startIcon={<CheckIcon />}
              onClick={submitForm}
            >
              <Typography variant="body2">
                {t("rfq:quotations:quoteAsBuyerModal:saveQuote")}
              </Typography>
            </CustomButton>
          </Box>
        </Box>
      </Box>
    </>
  );
};
