/**
 * This component is used  to display characteristics of selected element lik rfq, contracts and quotations.
 */

import React from "react";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { AppBar, Box, Divider, Grid } from "@mui/material";

// component to display title of quotation
import { DetailsSummaryHeader } from "./details-summary-header/details-summary-header.component";

// component to display characteristics of parts
import { DetailsSummaryPartTiles } from "./details-summary-part-tiles/details-summary-part-tiles.component";

// component to display total price and  fixed fees
import { DetailsSummaryTotalFees } from "./details-summary-total-fees/details-summary-total-fees.component";

//// component to display summary o quotation
import { DetailsSummaryFooter } from "./details-summary-footer/details-summary-footer.component";
import { StatsReport } from "./stats-report/stats-report.component";

// image imports
import cadImage from "assets/img/default-part-img.png";
import { getCharacteristicsOfPart } from "helpers/get-characteristics-of-part";
import { useQuotationViewData } from "components/marketplace/quotation-view/quotation-view-hooks";
import PartExtraFieldsTable from "components/quotation/project-part-info-table.container";
import { QuotationExtraFieldsTable } from "@next/modules/marketplace/components/quotation/quotation-extra-fields-table";
import { RFQPartsTable } from "@next/modules/project/components/part/rfq-part-table";

export const filterPartUploadList = (upload) => {
  let fileUrl = upload.find((partUpload) => partUpload.file_url !== null);
  if (fileUrl) {
    fileUrl = fileUrl.file_url;
  } else {
    fileUrl = cadImage;
  }
  return fileUrl;
};

function RfqsDetailsSummary(props) {
  const {
    startMessage,
    endMessage,
    title,
    quotation,
    showChart,
    dateToDisplay,
    subText,
    handleOpenModal,
    isSeller,
    isContract,
    attachments = [],
  } = props;

  const { quotationViewData, isPartsExtraFieldsAvailable, isQuotationExtraFieldsAvailable } =
    useQuotationViewData({ isContract });

  const profile = useSelector((state) => state.profile);
  // get the specific array of part with characteristics i want to display
  const characteristicsOfParts = getCharacteristicsOfPart(quotation);
  const subTotal = characteristicsOfParts.reduce((acc, part) => {
    return acc + part.price;
  }, 0);

  const { t } = useTranslation("workspace");

  return (
    <React.Fragment>
      <AppBar elevation={0} position="static" className="c-app-bar"></AppBar>
      <Grid container spacing={3} className="c-details-summary">
        <Grid item xs={12}>
          <DetailsSummaryHeader
            quotation={{ ...quotation, attachments }}
            startMessage={startMessage}
            submissionDate={quotation ? quotation?.created_at : ""}
            endMessage={endMessage}
            displayName={quotation ? quotation?.rfq_info?.rfq_display_name : ""}
            tag={quotation && quotation?.rfq_info && quotation?.rfq_info?.tag}
            contracting={
              quotation
                ? profile.company.type === "FAB"
                  ? quotation?.rfq_info?.company_name
                  : quotation?.company_info?.company_name
                : ""
            }
            dateToDisplay={dateToDisplay}
            rfqAdditionalDetails={quotation ? quotation?.rfq_info?.additional_details : ""}
            rfqAdditionalDetailsRichText={
              quotation ? quotation?.rfq_info?.additional_details_rich_text : ""
            }
            t={t}
            subText={subText}
            handleOpenModal={handleOpenModal}
          />
        </Grid>
        {!isSeller ? (
          <Grid item xs={12} style={{ marginTop: 16 }}>
            <RFQPartsTable readOnly parts={quotationViewData.parts} />
          </Grid>
        ) : (
          isPartsExtraFieldsAvailable && (
            <Grid item xs={12} style={{ background: "white", marginTop: 16 }}>
              <PartExtraFieldsTable
                parts={quotationViewData?.parts}
                partExtraFields={quotationViewData?.partExtraFields}
              />
            </Grid>
          )
        )}

        {isQuotationExtraFieldsAvailable && (
          <>
            <Grid item xs={12} style={{ background: "white", marginTop: 16 }}>
              <QuotationExtraFieldsTable
                activeQuote={{
                  parts: quotationViewData?.parts,
                  quotation_extra_fields: quotationViewData?.quotationExtraFields,
                  quote: {
                    quotationId: quotation.pk,
                    company_name: quotation?.company_info?.company_name,
                    rfq_display_name: quotation?.rfq_info?.rfq_display_name,
                  },
                }}
                partExtraFields={quotationViewData?.partExtraFields}
              />
            </Grid>
          </>
        )}
        {!isQuotationExtraFieldsAvailable && (
          <>
            {isSeller
              ? characteristicsOfParts.map((part) => {
                  return (
                    <DetailsSummaryPartTiles
                      part={part}
                      quotation={quotation ? quotation : {}}
                      t={t}
                      isSeller={isSeller}
                    />
                  );
                })
              : null}
            {showChart && (
              <Grid item xs={12}>
                <StatsReport t={t} />
              </Grid>
            )}
          </>
        )}

        <DetailsSummaryTotalFees
          subTotal={subTotal}
          fixedFees={quotation ? quotation?.fixed_charge : ""}
          totalPrice={quotation ? quotation?.total_price : ""}
          uploadList={quotation ? quotation?.quotation_uploads_list : []}
          t={t}
          isContract={isContract}
          commissionFee={quotation?.commission_fee}
        />

        <Grid item xs={12}>
          <Divider className="c-details-summary--divider" />
          <Box className="c-details-summary--summary">
            <DetailsSummaryFooter
              additionalDetails={quotation ? quotation?.additional_details : ""}
              additionalDetailsRichText={quotation ? quotation?.additional_details_rich_text : ""}
              leadTime={quotation?.lead_time}
              totalPrice={quotation ? quotation?.total_price : ""}
              title={title}
              t={t}
              commissionFee={quotation?.commission_fee}
              isQuotationExtraFieldsAvailable={isQuotationExtraFieldsAvailable}
            />
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
export default RfqsDetailsSummary;
