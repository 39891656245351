import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { calculateDateRange } from "../components/analytic-filters/analytic-filters.utils";
import {
  AnalyticFilters,
  AnalyticsState,
  FetchOrdersChartInput,
  FetchOrdersReportInput,
  FetchRequestsReportInput,
  OrdersReport,
  OrdersReportChart,
  RequestsReport,
  SupplierReport,
} from "./types";
import { CURRENCY_OPTIONS } from "@next/utils/countryList";

const dateObj = calculateDateRange("yearToDate");

export const initialState: AnalyticsState = {
  requestsReportLoading: false,
  requestsReportError: undefined,
  requestsReport: undefined,

  ordersReportLoading: false,
  ordersReportError: undefined,
  ordersReport: undefined,

  ordersVolumeChartLoading: false,
  ordersVolumeChartError: undefined,
  ordersVolumeChart: undefined,

  ordersExpectedReceiptValueChartLoading: false,
  ordersExpectedReceiptValueChartError: undefined,
  ordersExpectedReceiptValueChart: undefined,

  ordersOnTimeDeliveryChartLoading: false,
  ordersOnTimeDeliveryChartError: undefined,
  ordersOnTimeDeliveryChart: undefined,

  ordersItemLatenessChartLoading: false,
  ordersItemLatenessChartError: undefined,
  ordersItemLatenessChart: undefined,

  supplierReportLoading: false,
  supplierReportError: undefined,
  supplierReport: undefined,

  exportPOAnalyticsAsExcelLoading: false,
  exportPOAnalyticsAsExcelError: undefined,

  analyticFilters: {
    selectedSuppliers: [],
    timeframe: {
      key: "yearToDate",
      start: dateObj?.start,
      end: dateObj?.end,
    },
    selectedBuyers: [],
    currency: CURRENCY_OPTIONS.CAD,
  },
};

const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {
    fetchRequestsReportRequest: (state, action: PayloadAction<FetchRequestsReportInput>) => {
      state.requestsReportLoading = true;
      state.requestsReportError = undefined;
      state.requestsReport = undefined;
    },
    fetchRequestsReportFailure: (state, action: PayloadAction<Error>) => {
      state.requestsReportLoading = false;
      state.requestsReportError = action.payload;
    },
    fetchRequestsReportSuccess: (state, action: PayloadAction<RequestsReport>) => {
      state.requestsReportLoading = false;
      state.requestsReportError = undefined;
      state.requestsReport = action.payload;
    },
    fetchOrdersReportRequest: (state, action: PayloadAction<FetchOrdersReportInput>) => {
      state.ordersReportLoading = true;
      state.ordersReportError = undefined;
      state.ordersReport = undefined;
    },
    fetchOrdersReportFailure: (state, action: PayloadAction<Error>) => {
      state.ordersReportLoading = false;
      state.ordersReportError = action.payload;
    },
    fetchOrdersReportSuccess: (state, action: PayloadAction<OrdersReport>) => {
      state.ordersReportLoading = false;
      state.ordersReportError = undefined;
      state.ordersReport = action.payload;
    },
    fetchOrdersVolumeChartRequest: (state, _action: PayloadAction<FetchOrdersChartInput>) => {
      state.ordersVolumeChartLoading = true;
      state.ordersVolumeChartError = undefined;
      state.ordersVolumeChart = undefined;
    },
    fetchOrdersVolumeChartFailure: (state, action: PayloadAction<Error>) => {
      state.ordersVolumeChartLoading = false;
      state.ordersVolumeChartError = action.payload;
    },
    fetchOrdersVolumeChartSuccess: (state, action: PayloadAction<OrdersReportChart>) => {
      state.ordersVolumeChartLoading = false;
      state.ordersVolumeChartError = undefined;
      state.ordersVolumeChart = action.payload;
    },
    fetchOrdersExpectedReceiptValueChartRequest: (
      state,
      _action: PayloadAction<FetchOrdersChartInput>
    ) => {
      state.ordersExpectedReceiptValueChartLoading = true;
      state.ordersExpectedReceiptValueChartError = undefined;
      state.ordersExpectedReceiptValueChart = undefined;
    },
    fetchOrdersExpectedReceiptValueChartFailure: (state, action: PayloadAction<Error>) => {
      state.ordersExpectedReceiptValueChartLoading = false;
      state.ordersExpectedReceiptValueChartError = action.payload;
    },
    fetchOrdersExpectedReceiptValueChartSuccess: (
      state,
      action: PayloadAction<OrdersReportChart>
    ) => {
      state.ordersExpectedReceiptValueChartLoading = false;
      state.ordersExpectedReceiptValueChartError = undefined;
      state.ordersExpectedReceiptValueChart = action.payload;
    },
    fetchOrdersOnTimeDeliveryChartRequest: (
      state,
      _action: PayloadAction<FetchOrdersChartInput>
    ) => {
      state.ordersOnTimeDeliveryChartLoading = true;
      state.ordersOnTimeDeliveryChartError = undefined;
      state.ordersOnTimeDeliveryChart = undefined;
    },
    fetchOrdersOnTimeDeliveryChartFailure: (state, action: PayloadAction<Error>) => {
      state.ordersOnTimeDeliveryChartLoading = false;
      state.ordersOnTimeDeliveryChartError = action.payload;
    },
    fetchOrdersOnTimeDeliveryChartSuccess: (state, action: PayloadAction<OrdersReportChart>) => {
      state.ordersOnTimeDeliveryChartLoading = false;
      state.ordersOnTimeDeliveryChartError = undefined;
      state.ordersOnTimeDeliveryChart = action.payload;
    },
    fetchOrdersItemLatenessChartRequest: (state, _action: PayloadAction<FetchOrdersChartInput>) => {
      state.ordersItemLatenessChartLoading = true;
      state.ordersItemLatenessChartError = undefined;
      state.ordersItemLatenessChart = undefined;
    },
    fetchOrdersItemLatenessChartFailure: (state, action: PayloadAction<Error>) => {
      state.ordersItemLatenessChartLoading = false;
      state.ordersItemLatenessChartError = action.payload;
    },
    fetchOrdersItemLatenessChartSuccess: (state, action: PayloadAction<OrdersReportChart>) => {
      state.ordersItemLatenessChartLoading = false;
      state.ordersItemLatenessChartError = undefined;
      state.ordersItemLatenessChart = action.payload;
    },
    exportPOAnalyticsAsExcelRequest: (state) => {
      state.exportPOAnalyticsAsExcelLoading = true;
      state.exportPOAnalyticsAsExcelError = undefined;
    },
    exportPOAnalyticsAsExcelFailure: (state, action: PayloadAction<Error>) => {
      state.exportPOAnalyticsAsExcelLoading = false;
      state.exportPOAnalyticsAsExcelError = action.payload;
    },
    fetchSupplierReportRequest: (state) => {
      state.supplierReportLoading = true;
      state.supplierReportError = undefined;
      state.supplierReport = undefined;
    },
    fetchSupplierReportFailure: (state, action: PayloadAction<Error>) => {
      state.supplierReportLoading = false;
      state.supplierReportError = action.payload;
    },
    fetchSupplierReportSuccess: (state, action: PayloadAction<SupplierReport>) => {
      state.supplierReportLoading = false;
      state.supplierReportError = undefined;
      state.supplierReport = action.payload;
    },

    setAnalyticFilters: (state, action: PayloadAction<AnalyticFilters>) => {
      state.analyticFilters = action.payload;
    },
  },
});

export const analyticsActions = analyticsSlice.actions;

export default analyticsSlice.reducer;
