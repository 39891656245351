import React from "react";
import { useTranslation } from "react-i18next";

import { Grid, Tooltip, Typography } from "@mui/material";

import { GridCellParams, GridColDef } from "@mui/x-data-grid-pro-v5";
import { normalizePartExtraFieldsForPart } from "@next/modules/marketplace/components/quotation/quotation-extra-fields-table.utils";
import { DataGridProV5 } from "@next/components/data-grid-pro-v5";

const AdditionalDetailsCell: React.FC<GridCellParams> = ({ value }) => {
  return (
    <Tooltip title={value as string}>
      <Typography className="c-ellipsis" variant="body2">
        {value as string}
      </Typography>
    </Tooltip>
  );
};

/**
 * Props
 */
interface Props {
  partExtraFields: any;
  parts: any;
}
const DataTable: React.FC<Props> = ({ parts, partExtraFields }) => {
  const { t } = useTranslation();
  /**
   * Default common column config
   */
  const defaultColumnConfig: Omit<GridColDef, "field"> = {
    disableColumnMenu: true,
    sortable: false,
    hideSortIcons: true,
    flex: 1,
    headerClassName: "quotation-column-header",
  };

  /**
   * Columns definition
   */
  const columns: GridColDef[] = [
    {
      ...defaultColumnConfig,
      headerName: t("project:table:header:partNumber"),
      field: "name",
      flex: 1,
      editable: false,
      minWidth: 100,
    },
    {
      ...defaultColumnConfig,
      headerName: t("project:table:header:description"),
      field: "additional_details",
      renderCell: AdditionalDetailsCell,
      flex: 1,
      editable: false,
      minWidth: 150,
    },
    {
      ...defaultColumnConfig,
      headerName: t("project:table:header:quantity"),
      field: "quantity",
      flex: 1,
      editable: false,
      minWidth: 75,
    },
    ...partExtraFields.map((field: any) => ({
      ...defaultColumnConfig,
      field: field.slug,
      headerName: field.display_name["en"],
      type: field.type,
      valueOptions: field.valueOptions,
      flex: 1,
      minWidth: 100,
    })),
  ];

  const rows = parts.map((part: any) => {
    let obj = {
      pk: part.pk,
      name: part.name,
      additional_details: part.additional_details,
      quantity: part.quantity,
    };

    if (part?.extra_fields) {
      obj = {
        ...obj,
        ...normalizePartExtraFieldsForPart(part?.extra_fields),
      };
    }

    return obj;
  });

  return (
    <Grid container style={{ flexDirection: "column", background: "#f7f7f7" }} xs={12}>
      <Grid item style={{ padding: 10 }}>
        {" "}
        <Typography variant="h6">{t("project:table:title:partInfo")}</Typography>
      </Grid>
      <Grid>
        <div style={{ display: "flex", width: "100%" }} className="c-project__table">
          <div style={{ flexGrow: 1, position: "relative" }}>
            <DataGridProV5
              autoHeight
              rows={rows}
              columns={columns}
              disableSelectionOnClick={true}
              hideFooterPagination={true}
              autoPageSize={true}
              getRowId={(row) => row.pk}
            />
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default DataTable;
