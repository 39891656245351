import React from "react";
import { useSelector } from "react-redux";
import { getModal } from "@next/redux/modalsSlices";
import { ProjectModalTypes } from "./types";
import { Rfq } from "../redux";
import { AppBar, Box, Grid, IconButton, Toolbar, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import BuyerRfqsView from "components/workspace/buyer-rfqs-view/buyer-rfqs-view.container";

import { modalsActions } from "@next/redux/modalsSlices";
import { useDispatch } from "react-redux";

export const ProjectRfqDetailsModal = () => {
  const dispatch = useDispatch();

  const modal = useSelector(getModal(ProjectModalTypes.RFQ_DETAILS_MODAL));
  const rfq = modal?.data as Rfq;

  const onClickCloseButton = () => {
    dispatch(modalsActions.closeModal(ProjectModalTypes.RFQ_DETAILS_MODAL));
  };

  return (
    <AppBar position="static" color="transparent" className="project-rfq-modal">
      <Toolbar>
        <Box display="flex" flexGrow={1}>
          <Typography variant="h6">{rfq?.display_name || rfq?.rfq_display_name}</Typography>
        </Box>

        <IconButton
          edge="start"
          color="inherit"
          aria-label="header"
          onClick={onClickCloseButton}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </Toolbar>
      <Grid xs={12} container style={{ paddingLeft: 56, height: "100%" }}>
        <BuyerRfqsView hideHeader params={{ id: rfq?.pk, isProject: true }} />
      </Grid>
    </AppBar>
  );
};
