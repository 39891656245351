import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FieldArray, Form, Formik } from "formik";
import { Box, Checkbox, Divider, Typography } from "@mui/material";
import CustomButton from "@next/components/custom-button";
import UsersPaginatedAutoComplete from "../components/rfq-creation-form/users-paginated-autocomplete";
import { workspaceNextActions } from "../redux";
import { selectSupplierContactsList } from "../redux/selectors";
import { modalsActions } from "@next/redux/modalsSlices";
import { SharedModalTypes } from "@next/modals/types";
import CustomListItem from "../components/rfq-creation-form/custom-list-item";
import GenericAvatar from "@next/components/generic-avatar";
import { getUserDisplayName } from "@next/modules/profile/helpers/profile-helpers";
import { DistributionForSupplierContacts } from "@next/modals/shared-supplier-contact-lists-modal";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme, alpha } from "@mui/material";
import { SupplierContactsListProps } from "../components/supplier-contacts-list/supplier-contacts-list";
import { getCompany } from "services/profile/profile.selectors";
import snackbarUtils from "@next/utils/snackbarUtils";

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      gap: "16px",
    },
    contentContainer: {
      display: "flex",
      alignItems: "center",
      padding: "10px 12px",
      borderRadius: "8px",
      gap: "8px",
      backgroundColor: (props: { cautionContent?: boolean }) =>
        props.cautionContent
          ? alpha(theme.palette.warning.main, 0.15)
          : alpha(theme.palette.primary.main, 0.15),
    },
    contactlistHeader: {
      borderTop: `1px solid ${theme.palette.divider}`,
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    tableRow: {
      padding: "12px 8px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    checkboxContainer: {
      minWidth: "200px",
      textAlign: "center",
    },
  })
);

const WarningContent = ({ t, distributionForRfq }) => (
  <>
    <ErrorOutlineIcon color="warning" />
    <Typography variant="body2">
      {t("workspaceNext:supplierContactsList:manageModal:content_particular", {
        type: distributionForRfq
          ? t("workspaceNext:supplierContactsList:manageModal:request")
          : t("workspaceNext:supplierContactsList:manageModal:order"),
      })}
    </Typography>
  </>
);

const InfoContent = ({ t }) => (
  <>
    <InfoOutlinedIcon color="primary" />
    <Typography variant="body2">
      {t("workspaceNext:supplierContactsList:manageModal:content")}
    </Typography>
  </>
);

const ContactListWithSeperateModules = ({ values, classes, t, setFieldValue }) => (
  <Box>
    <Box className={`${classes.contactlistHeader} ${classes.tableRow}`}>
      <Typography variant="body2">
        {t("workspaceNext:supplierContactsList:manageModal:name")}
      </Typography>
      <Box display="flex" mr="25px">
        <Typography variant="body2" className={classes.checkboxContainer}>
          {t("workspaceNext:supplierContactsList:manageModal:requests")}
        </Typography>
        <Typography variant="body2" className={classes.checkboxContainer}>
          {t("workspaceNext:supplierContactsList:manageModal:orders")}
        </Typography>
      </Box>
    </Box>

    <FieldArray
      name="target_profiles"
      render={(arrayHelpers) =>
        (Array.isArray(values.target_profiles) ? values.target_profiles : []).map((user, index) => {
          const displayName = getUserDisplayName(user);
          return (
            <CustomListItem
              key={index}
              onRemove={() => arrayHelpers.remove(index)}
              rootClassName={classes.tableRow}
            >
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box display="flex" alignItems="center" gap="8px">
                  <GenericAvatar
                    url={user.picture || ""}
                    name={displayName || user.email}
                    size="large"
                  />
                  <Box>
                    <Typography variant="body2">{displayName}</Typography>
                    <Typography variant="body2" color="textSecondary">
                      {user.email}
                    </Typography>
                  </Box>
                </Box>
                <Box display="flex" alignItems="center">
                  <Box className={classes.checkboxContainer}>
                    <Checkbox
                      onChange={(e) => {
                        const updatedUser = {
                          ...user,
                          is_rfq_distribution: e.target.checked,
                        };
                        const updatedUsers = values.target_profiles.map((u) =>
                          u.id === user.id ? updatedUser : u
                        );
                        setFieldValue("target_profiles", updatedUsers);
                      }}
                      checked={user.is_rfq_distribution}
                    />
                  </Box>
                  <Box className={classes.checkboxContainer}>
                    <Checkbox
                      onChange={(e) => {
                        const updatedUser = {
                          ...user,
                          is_po_distribution: e.target.checked,
                        };
                        const updatedUsers = values.target_profiles.map((u) =>
                          u.id === user.id ? updatedUser : u
                        );
                        setFieldValue("target_profiles", updatedUsers);
                      }}
                      checked={user.is_po_distribution}
                    />
                  </Box>
                </Box>
              </Box>
            </CustomListItem>
          );
        })
      }
    />
  </Box>
);

export const SupplierContactsForm: React.FC<SupplierContactsListProps> = ({
  supplier,
  users,
  onValidate,
  loadingSelector,
  distributionForSupplierContacts,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const submitLoading = useSelector(loadingSelector || (() => false));
  const options = useSelector(selectSupplierContactsList);
  const company = useSelector(getCompany);

  const setFieldValueRef = useRef(null);

  const appendDistributionFieldsToUsers = useCallback((user) => {
    return {
      ...user,
      is_rfq_distribution: user.is_rfq_distribution === undefined ? true : user.is_rfq_distribution,
      is_po_distribution: user.is_po_distribution === undefined ? true : user.is_po_distribution,
    };
  }, []);

  const isOrderManagementEnabled = company?.is_order_management_enabled;
  const isRequestsEnabled = company?.is_requests_enabled;
  const distributionForRfq =
    distributionForSupplierContacts === DistributionForSupplierContacts.RFQ;
  const distributionForOrder =
    distributionForSupplierContacts === DistributionForSupplierContacts.ORDER;

  const showContactListWithSeperateModules =
    !(distributionForRfq || distributionForOrder) && isRequestsEnabled && isOrderManagementEnabled;

  const [targetProfiles, setTargetProfiles] = useState(
    users.map((user) => appendDistributionFieldsToUsers(user)) || []
  );

  const classes = useStyles({
    cautionContent: distributionForRfq || distributionForOrder,
  });

  useEffect(() => {
    dispatch(
      workspaceNextActions.fetchSupplierContactsListRequest({
        supplierId: supplier?.pk || supplier?.id,
        distributionForSupplierContacts: DistributionForSupplierContacts.ALL,
      })
    );
  }, [dispatch, supplier]);

  const onInviteSuccess = (newUser) => {
    const updatedNewUser = appendDistributionFieldsToUsers(newUser);
    setTargetProfiles((prevProfiles) => {
      if (setFieldValueRef.current) {
        setFieldValueRef.current(
          "target_profiles",
          [...prevProfiles, updatedNewUser].filter(
            (user, index, self) => self.findIndex((u) => u.id === user.id) === index
          )
        );
      }
      return [...prevProfiles, updatedNewUser];
    });
  };

  const handleSelectionChange = (selectedUsers) => {
    const updatedProfiles = selectedUsers
      .filter((user) => !user.__invite)
      .map((user) => appendDistributionFieldsToUsers(user));
    setTargetProfiles(updatedProfiles);
    if (setFieldValueRef.current) {
      setFieldValueRef.current("target_profiles", updatedProfiles);
    }
  };

  const onSubmit = useCallback(
    (values) => {
      const canValidate =
        !showContactListWithSeperateModules ||
        values.target_profiles.every((user) => user.is_rfq_distribution || user.is_po_distribution);

      if (canValidate) {
        onValidate && onValidate(values.target_profiles);
      } else {
        snackbarUtils.error(t("workspaceNext:supplierContactsList:manageModal:validationError"));
      }
    },
    [onValidate, showContactListWithSeperateModules, t]
  );

  return (
    <Formik initialValues={{ target_profiles: targetProfiles }} onSubmit={onSubmit}>
      {({ values, setFieldValue }) => {
        setFieldValueRef.current = setFieldValue;

        return (
          <Form className={classes.root}>
            <Box className={classes.contentContainer}>
              {distributionForRfq || distributionForOrder ? (
                <WarningContent t={t} distributionForRfq={distributionForRfq} />
              ) : (
                <InfoContent t={t} />
              )}
            </Box>

            <UsersPaginatedAutoComplete
              fieldName="target_profiles"
              placeholder={t("workspaceNext:supplierContactsList:manageModal:searchByNameOrEmail")}
              loading={submitLoading}
              showSelected={!showContactListWithSeperateModules}
              valueKey={undefined}
              options={options}
              allowInvite
              onInvite={(email) => {
                dispatch(
                  modalsActions.showModal({
                    key: SharedModalTypes.INVITE_SUPPLIER_CONTACT_MODAL,
                    data: {
                      email,
                      onSuccess: onInviteSuccess,
                      supplierId: supplier?.pk || supplier?.id,
                    },
                  })
                );
              }}
              onSelectionChange={handleSelectionChange}
            />

            {values.target_profiles.length > 0 && showContactListWithSeperateModules && (
              <ContactListWithSeperateModules
                values={values}
                classes={classes}
                t={t}
                setFieldValue={setFieldValue}
              />
            )}

            <Box mx={-2}>
              <Divider />
            </Box>

            <Box display="flex" justifyContent="flex-end">
              <CustomButton
                loading={submitLoading}
                disabled={submitLoading}
                type="submit"
                color="primary"
                variant="contained"
              >
                {t("workspaceNext:supplierContactsList:manageModal:submit")}
              </CustomButton>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};
