import i18n from "assets/configi18n/i18n";
import { ProviderContext, VariantType, useSnackbar } from "notistack";
import React from "react";

export enum SnackbarNextVariant {
  CUSTOM_SNACKBAR = "customSnackbar",
}

let useSnackbarRef: ProviderContext;
export const SnackbarUtilsConfigurator: React.FC = () => {
  useSnackbarRef = useSnackbar();
  return null;
};

const snackbarUtils = {
  success(msg: string) {
    this.toast(msg, "success");
  },
  warning(msg: string) {
    this.toast(msg, "warning");
  },
  info(msg: string) {
    this.toast(msg, "info");
  },
  error(msg: string) {
    this.toast(msg, "error");
  },
  toast(msg: string, variant: VariantType = "default") {
    useSnackbarRef.enqueueSnackbar(msg, { variant });
  },
  custom(msg: string, CustomSnackbarProps) {
    useSnackbarRef.enqueueSnackbar(msg, {
      ...CustomSnackbarProps,
      variant: SnackbarNextVariant.CUSTOM_SNACKBAR,
    });
  },
};

export const showCopiedToClipboardMessage = () => {
  useSnackbarRef.enqueueSnackbar({
    message: i18n.t("common:copiedToClipboard") as string,
    autoHideDuration: 2000,
  });
};

export default snackbarUtils;
