import { GridRowParams, useGridApiRef } from "@mui/x-data-grid-pro-v5";
import useUpdateEffect from "@next/hooks/useUpdateEffect";
import React from "react";
import { useTranslation } from "react-i18next";
import { Part } from "../../redux";
import { useCreateMultiContractTableData } from "./create-multi-contract-table.hooks";
import { CreateMultiContractData, SupplierKeyValue } from "./create-multi-contract-table.types";
import { createStyles, makeStyles } from "@mui/styles";
import { DataGridProV5 } from "@next/components/data-grid-pro-v5";

const useStyles = makeStyles((theme) =>
  createStyles({
    title: { paddingTop: theme.spacing(3), paddingBottom: theme.spacing(3) },
    table: {
      maxHeight: 350,
      overflow: "auto",
    },
    tableRoot: {
      borderTop: "none",
      borderBottom: "none",
    },
    tableRow: {
      "&:nth-last-child(1)": {
        "& .MuiDataGrid-cell": {
          borderBottom: "none",
        },
      },
    },
  })
);

type Props = {
  parts: Part[] | undefined;
  suppliersOptions?: SupplierKeyValue[];
  onChange: (data: CreateMultiContractData[]) => void;
  filterSuppliers?: boolean;
};

export const CreateMultiContractTable: React.FC<Props> = ({
  parts = [],
  suppliersOptions = [],
  onChange,
  filterSuppliers = true,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const apiRef = useGridApiRef();

  const { gridData } = useCreateMultiContractTableData(parts, suppliersOptions, filterSuppliers);

  useUpdateEffect(() => {
    // If nothing is selected, we should set the initial state to state to leave in rfq.
    onEditRowsModelChange();
  }, [gridData?.rows]);

  const onEditRowsModelChange = () => {
    if (apiRef) {
      onChange(Array.from(apiRef.current?.getRowModels().values()) as CreateMultiContractData[]);
    }
  };

  const getRowClassName = (params: GridRowParams) =>
    `c-highlighted-row--${params.row?.supplier_id ? true : false}`;

  return (
    <div className={classes.table}>
      <DataGridProV5
        apiRef={apiRef}
        autoHeight
        autoPageSize
        rows={gridData?.rows || []}
        columns={gridData?.columns || []}
        hideFooter
        disableSelectionOnClick
        headerHeight={40}
        rowHeight={40}
        getRowClassName={getRowClassName}
        onEditRowsModelChange={onEditRowsModelChange}
        classes={{ root: classes.tableRoot, row: classes.tableRow }}
      />
    </div>
  );
};
