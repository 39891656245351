import { Box, Typography } from "@material-ui/core";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import { MenuItem } from "@mui/material";
import { showCopiedToClipboardMessage } from "@next/utils/snackbarUtils";
import React, { FC } from "react";
import CopyToClipboard from "react-copy-to-clipboard";

type Props = {
  label: string;
  onCopy?: () => void;
};

export const CopiedMenuItem: FC<Props> = ({ label, onCopy }) => {
  return (
    <MenuItem>
      <CopyToClipboard
        text={label}
        onCopy={() => {
          showCopiedToClipboardMessage();
          onCopy?.();
        }}
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          minWidth={"240px"}
          width={"100%"}
          height={"30px"}
        >
          <Typography variant="body2" style={{ marginRight: "24px" }}>
            {label}
          </Typography>

          <ContentCopyRoundedIcon
            style={{
              width: "16px",
              color: "black",
            }}
          />
        </Box>
      </CopyToClipboard>
    </MenuItem>
  );
};
