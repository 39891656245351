import { connect } from "react-redux";
import { modalsActions } from "../../../services/modals/modals.actions";
import { workspaceActions } from "../../../services/workspace";
import { SupplierContractView } from "./supplier-contract-view.component";
const mapDispatchToProps = {
  fetchContractDetails: workspaceActions.fetchContractDetails,
  handleOpenModal: modalsActions.showModal,
};

const mapStateToProps = (state) => {
  return {
    token: state.profile.token,
    company: state.profile.company,
    contractDetails: state.workspace.contractDetails,
    isContractDetailsLoading: state.workspace.isContractDetailsLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SupplierContractView);
