import React from "react";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import { UploadQuotationFormBase } from "./upload-quotation.form.base";
import { FormValues } from "./upload-quotation.form.types";
import { UploadQuotationAsBuyerModal } from "../../modals/upload-quotation-as-buyer-modal";
import * as yup from "yup";
import { workspaceNextActions } from "../../redux";
import { useQueryParam } from "@next/hooks/useQuery";

type Props = {
  onClose: () => void;
  quotationData: UploadQuotationAsBuyerModal;
};

const UploadQuotationForm: React.FC<Props> = ({ onClose, quotationData }) => {
  const dispatch = useDispatch();
  const [_expandedQuote, setExpandedQuote] = useQueryParam("expandedQuote");
  const [_rfqTab, setRfqTab] = useQueryParam("rfqTab");

  const initialValues: FormValues = {
    parts: quotationData?.parts || [],
    currency: quotationData?.currency || "CAD",
    delay: parseFloat(Number(quotationData?.delay).toFixed(0)),
    delay_unit: quotationData?.delay_unit || "d",
    files: quotationData?.files || [],
    fixed_charge: parseFloat(Number(quotationData?.fixed_charge).toFixed(2)),
    supplier_id: quotationData?.supplier_id,
    total_price: parseFloat(Number(quotationData?.total_price).toFixed(2)),
  };

  const validationSchema = yup.object().shape({
    delay_unit: yup.string().required(),
    total_price: yup.number().required(),
  });

  const handleQuotationUploadSuccess = (response) => {
    onClose();
    setExpandedQuote(response?.pk ? response?.pk : "");
    setRfqTab("2");
  };

  const handleUploadQuotation = (values: FormValues) => {
    const payload = {
      parts: values.parts.map((part) => ({
        part_id: part.part_id || part.pk,
        unit_price: part.price ? parseFloat(Number(part.price).toFixed(2)) : 0,
      })),
      supplier_id: values.supplier_id,
      delay: values.delay ? parseFloat(values.delay.toFixed(2)) : 0,
      delay_unit: values.delay_unit,
      currency: values.currency,
      files: values.files.map((file) => file.pk),
      fixed_charge: values.fixed_charge ? parseFloat(Number(values.fixed_charge).toFixed(2)) : 0,
      total_price: values.total_price ? parseFloat(Number(values.total_price).toFixed(2)) : 0,
      rfq_id: quotationData?.rfq_id,
    };
    dispatch(
      workspaceNextActions.uploadQuotationAsBuyerRequest({
        data: payload,
        quotation_id: quotationData?.quotation_id,
        onSuccess: (response) => handleQuotationUploadSuccess(response),
      })
    );
  };

  return (
    <Formik
      render={(props) => (
        <UploadQuotationFormBase
          onClose={onClose}
          supplier_picture={quotationData?.supplier_picture}
          pdf_parsing_result={quotationData?.pdf_parsing_result}
          {...props}
        />
      )}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleUploadQuotation}
    />
  );
};

export default UploadQuotationForm;
