/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import { CreateMultiContractData, SupplierKeyValue } from "./create-multi-contract-table.types";
import { getCreateMultiContractTableColumns } from "./create-multi-contract-table.columns";
import { Part } from "../../redux";
import { GridColumns, GridRowModel } from "@mui/x-data-grid-pro-v5";

export type GridDataState = {
  rows: GridRowModel[];
  columns: GridColumns;
};

export const useCreateMultiContractTableData = (
  parts: Partial<Part>[],
  suppliersOptions: SupplierKeyValue[],
  filterSuppliers?: boolean
) => {
  const [gridData, setGridData] = useState<GridDataState>({
    rows: [],
    columns: [],
  });

  useEffect(() => {
    setGridData({
      rows: parts?.map((item, index) => {
        return { id: index, part: item };
      }) as CreateMultiContractData[],
      columns: getCreateMultiContractTableColumns(suppliersOptions, filterSuppliers),
    });
  }, [parts, suppliersOptions]);

  return {
    gridData,
    setGridData,
  };
};
