import { Box } from "@material-ui/core";
import { BoxProps } from "@mui/material";
import React, { FC } from "react";

export const CenteredBox: FC<BoxProps> = (props) => {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      width={"100%"}
      height={"100%"}
      {...props}
    />
  );
};
