import React from "react";
import { DataGridProV5 } from "@next/components/data-grid-pro-v5";
import { getQuotationLineItemsTableColumns } from "./quotation-line-items-table.columns";
import { GridRowParams, useGridApiRef } from "@mui/x-data-grid-pro-v5";
import { FormikErrors } from "formik";
import { FormValues } from "../upload-quotation.form.types";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { Theme, alpha } from "@mui/material";
import { createStyles } from "@mui/styles";

type Props = {
  setFieldValue?: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<FormValues>>;
  parts: any;
  currency: string;
  pdf_parsing_result?: any;
};

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    table: {
      "& .highlightWarningRow": {
        backgroundColor: alpha(theme.palette.warning.main, 0.1),
      },
    },
  })
);

export const QuotationLineItemTable: React.FC<Props> = ({
  setFieldValue,
  parts,
  currency,
  pdf_parsing_result,
}) => {
  const apiRef = useGridApiRef();
  const { t } = useTranslation();
  const classes = useStyles();
  const columns = getQuotationLineItemsTableColumns({
    setFieldValue,
    parts,
    currency,
    t,
  });

  const getRowClassName = (params: GridRowParams) => {
    const partAIParsingWarning =
      pdf_parsing_result &&
      pdf_parsing_result?.unmatched_rfq_part_pks?.includes(params.row.part_id);
    if (partAIParsingWarning) {
      return "highlightWarningRow";
    }

    return "";
  };

  return (
    <DataGridProV5
      apiRef={apiRef}
      rows={parts || []}
      className={classes.table}
      columns={columns || []}
      onCellClick={(_params, e) => {
        e.stopPropagation();
      }}
      rowHeight={40}
      headerHeight={44}
      showColumnRightBorder
      getRowClassName={getRowClassName}
      showCellRightBorder
      getRowId={(row) => row.pk}
      disableSelectionOnClick
      hideFooter
      sx={{
        "&.MuiDataGrid-root": {
          border: "none",
        },
      }}
    />
  );
};
