import { useCallback, useEffect, useState } from "react";
import { getExtraFieldsTableColumns } from "./quotation-extra-fields-table.columns";
import useLocalStorage from "hooks/useLocalStorage";
import { isEmpty } from "lodash";
import { Part, Rfq } from "@next/modules/project/redux";
import { normalizeQuatationFields } from "components/quotation/project-part-info-table.utils";
import { normalizePartExtraFieldsForPart } from "./quotation-extra-fields-table.utils";
import { useSelector } from "react-redux";
import { selectCustomQuotationImportedData } from "../../redux/selectors";
import { GridColDef, GridRowModel } from "@mui/x-data-grid-pro-v5";
import { GenericObject } from "@next/modules/profile/redux";

type GridStateData = {
  rows: Part[];
  columns: GridColDef[];
};

export const useExtraFieldsTableData = (
  activeQuote: Rfq,
  partExtraFields: any,
  extraFieldsRowModels: Map<React.ReactNode, GridRowModel>,
  isEditable: boolean
) => {
  const [gridData, setGridData] = useState<GridStateData>();

  // Select API imported data from the imported excel
  const customQuotationImportedData = useSelector(selectCustomQuotationImportedData);
  useEffect(() => {
    if (activeQuote?.quotation_extra_fields) {
      const quotationExtraFields = normalizeQuatationFields(activeQuote?.quotation_extra_fields);

      const columns = getExtraFieldsTableColumns(
        quotationExtraFields,
        partExtraFields,
        isEditable,
        activeQuote?.hide_quotation_sensitive_data
      );

      setGridData((data) => ({
        rows: data?.rows || [],
        columns,
      }));
    }
  }, [
    activeQuote?.quotation_extra_fields,
    partExtraFields,
    isEditable,
    activeQuote?.hide_quotation_sensitive_data,
  ]);

  useEffect(() => {
    if (activeQuote?.quotation_extra_fields) {
      const rows =
        activeQuote.parts?.map((part: Part, ix) => {
          const { pk = ix, extra_fields, quotation_extra_fields, ...restFields } = part;
          let obj: GenericObject = restFields;
          obj.pk = pk;

          if (quotation_extra_fields) {
            obj = { ...obj, ...quotation_extra_fields };
          }

          if (extra_fields) {
            obj = {
              ...obj,
              ...normalizePartExtraFieldsForPart(extra_fields),
            };
          }

          if (!isEmpty(extraFieldsRowModels)) {
            try {
              const objValues = extraFieldsRowModels.get(part.pk);

              if (objValues) {
                obj = { ...obj, ...objValues };
              }
            } catch (error) {
              console.log("error with saved extra fields");
            }
          }

          const importedPart = customQuotationImportedData.find(
            (importedPart: any) => importedPart?.pk === part?.pk
          );

          if (importedPart) {
            obj = { ...obj, ...importedPart.quotation_extra_fields };
          }

          return obj as Part;
        }) || [];

      setGridData((data) => ({
        rows,
        columns: data?.columns || [],
      }));
    }
  }, [activeQuote, extraFieldsRowModels, customQuotationImportedData]);

  return {
    gridData,
  };
};

export const useSavedExtraFieldsRowModels = (
  activeQuotePK: string | number,
  disableLocalStorageValues?: boolean
) => {
  const [savedExtraFieldsRowModels, setSavedExtraFieldsRowModels] = useLocalStorage(
    `${activeQuotePK}_SAVED_EXTRA_FIELDS_ROW_MODELS`,
    ""
  );

  const [extraFieldsState, setExtraFieldsState] = useState(new Map());

  useEffect(() => {
    try {
      if (!isEmpty(savedExtraFieldsRowModels)) {
        setExtraFieldsState(new Map(JSON.parse(savedExtraFieldsRowModels)));
      }
    } catch (error) {
      console.log("error initializeState saved extra fields");
      setExtraFieldsState(new Map());
    }
  }, [savedExtraFieldsRowModels]);

  const saveExtraFieldsRowModels = useCallback(
    (saveFieldsRowModel: Map<React.ReactText, GridRowModel>) => {
      if (disableLocalStorageValues) {
        return setExtraFieldsState(saveFieldsRowModel);
      }

      if (saveFieldsRowModel) {
        setSavedExtraFieldsRowModels(JSON.stringify(Array.from(saveFieldsRowModel.entries())));
      }
    },
    [setSavedExtraFieldsRowModels, disableLocalStorageValues]
  );

  const clearSavedExtraFieldsRowModels = () => {
    setSavedExtraFieldsRowModels("");
  };

  return {
    extraFieldsRowModels: extraFieldsState,
    saveExtraFieldsRowModels,
    clearSavedExtraFieldsRowModels,
  };
};
