import { axiosApi } from "@next/api";
import { backendUrl } from "urls";
import {
  AnalyticFilters,
  FetchOrdersChartInput,
  OrdersReport,
  RequestsReport,
  SupplierReport,
} from "../redux";
import { generateReportQuery } from "../utils/generateReportQuery";
import { delay } from "@next/utils/miscUtils";

const fetchRequestReport = (filters: AnalyticFilters) => {
  const query = generateReportQuery("requests", filters);

  return axiosApi.get<RequestsReport>(`${backendUrl.analyticsRfqs}/${query}`);
};

const fetchOrdersReport = (filters: AnalyticFilters) => {
  const query = generateReportQuery("orders", filters);

  return axiosApi.get<OrdersReport>(`${backendUrl.analyticsOrders}/${query}`);
};

const fetchOrdersChart = (filters: FetchOrdersChartInput) => {
  const query = generateReportQuery("orders", filters, filters.chart_type);
  return axiosApi.get<OrdersReport>(`${backendUrl.analyticsOrders}/chart${query}`);
};

const exportPOAnalyticsAsExcel = (filters: AnalyticFilters) => {
  const query = generateReportQuery("orders", filters);
  return axiosApi.get(`${backendUrl.analyticsOrdersExcelExport}${query}`, {
    responseType: "blob",
  });
};

const fetchOrdersVolumeChart = async (filters: AnalyticFilters) => {
  const query = generateReportQuery("orders", filters);
  await delay(1000);

  return {
    data: {
      id: "po_volume",

      x_axis: [
        "2023-12-01",
        "2024-01-01",
        "2024-02-01",
        "2024-03-01",
        "2024-04-01",
        "2024-05-01",
        "2024-06-01",
        "2024-07-01",
        "2024-08-01",
        "2024-09-01",
        "2024-10-01",
        "2024-11-01",
        "2024-12-01",
      ],
      y_axes: [
        {
          id: "po_total_value",
          values: [0, 0, 137, 0, 0, 14, 0, 121, 198, 0, 56, 75, 8],
        },
        {
          id: "po_items_count",
          values: [0, 0, 244, 2, 1, 23, 29, 43, 62, 15, 29, 84, 7],
        },
        {
          id: "cumulative_value",
          values: [0, 0, 137, 137, 137, 151, 151, 272, 470, 470, 526, 601, 609],
        },
      ],
    },
  };
};

const fetchSupplierReport = () => {
  return axiosApi.get<SupplierReport>(`${backendUrl.analyticsRfqs}`);
};

export const analyticsService = {
  fetchRequestReport,
  fetchOrdersReport,
  fetchOrdersVolumeChart,
  fetchSupplierReport,
  fetchOrdersChart,
  exportPOAnalyticsAsExcel,
};
