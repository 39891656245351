// General imports

// Hooks
import { connect } from "react-redux";

// Material UI

// Images

// Components
import { SupplierQuoteView } from "./supplier-quote-view.component";

// Helpers functions

// Actions
import { quotationActions } from "../../../services/quotation/quotation.actions";
import { modalsActions } from "../../../services/modals/modals.actions";

const mapDispatchToProps = {
  getQuotationDetails: quotationActions.getQuotationDetails,
  setIsEditQuotationMode: quotationActions.setIsEditQuotationMode,
  handleOpenModal: modalsActions.showModal,
  handleCloseModal: modalsActions.hideModal,
};
const mapStateToProps = (state) => {
  return {
    isQuotationDetailsLoading: state.quotation.isQuotationDetailsLoading,
    quotationDetails: state.quotation.quotationDetails,
    isEditQuotationLoading: state.quotation.isEditQuotationLoading,
    isEditQuotationDialogOpen: state.modals.isEditQuotationDialogOpen,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SupplierQuoteView);
