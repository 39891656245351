import { connect } from "react-redux";

import { BuyerRfqsView } from "./buyer-rfqs-view.component";
// Action Import
import { workspaceActions } from "../../../services/workspace";
import { modalsActions } from "../../../services/modals/modals.actions";

const mapDispatchToProps = {
  fetchRFQDetails: workspaceActions.fetchRFQDetails,
  selectWinner: workspaceActions.selectWinner,
  setBuyerQuotationsTabs: workspaceActions.setBuyerQuotationsTabs,
  handleOpenModal: modalsActions.showModal,
  handleCloseModal: modalsActions.hideModal,
  setCongratulationViewDetails: workspaceActions.setCongratulationViewDetails,
};

const mapStateToProps = (state) => {
  return {
    rfq: state.workspace.rfq,
    profile: state.profile,
    token: state.profile.token,
    company: state.profile.company,
    RFQList: state.workspace.RFQListOfCompany,
    rfqDetails: state.workspace.rfqDetails,
    isRFQDetailsLoading: state.workspace.isRFQDetailsLoading,
    isQuotationsTabs: state.workspace.isQuotationsTabs,
    congratsInfo: state.workspace.congratsInfo,
    isPurchaseOrderModalOpen: state.modals.isPurchaseOrderModalOpen,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BuyerRfqsView);
