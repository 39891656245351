/**
 * This component is used in  supplier side to display characteristics of selected submit quotations in the list of offers.
 */
// General imports
import React, { useCallback, useEffect, useState } from "react";

import { matchPath, useLocation } from "react-router-dom";

// Hooks
import { useTranslation } from "react-i18next";

// Material UI
import { AppBar, Box, CircularProgress, Divider, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";

// Images

// Components
import RfqsDetailsSummary from "components/rfqs-details-summary/rfqs-details-summary.component";
import BackButton from "components/back-button/back-button.component";
import { TabsView } from "components/utils/tabs-view/tabs-view.component";
import { EditQuotationDialog } from "components/edit-quotation-dialog/edit-quotation-dialog.component";
import { TabName } from "components/common/table-list/types";

// Helpers functions
import { history } from "helpers/history";
import { frontendUrl } from "urls";
import { QuestionsAnswers } from "@next/modules/workspace/components/questions-answers/questions-answers";
import { useQuery } from "@next/hooks/useQuery";

/**
 * TabView generic child component
 */
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Grid
      container
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Grid item xs={12}>
          {children}
        </Grid>
      )}
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  divider: {
    width: "auto",
    marginLeft: "-64px",
    marginRight: "-64px",
  },
}));

export const SupplierQuoteView = ({
  profile,
  params,
  getQuotationDetails,
  isQuotationDetailsLoading,
  quotationDetails,
  handleOpenModal,
  isEditQuotationDialogOpen,
  setIsEditQuotationMode,
  isEditQuotationLoading,
}) => {
  const { t } = useTranslation("workspace");
  const classes = useStyles();

  const location = useLocation();

  // tab names
  let tabItems = [
    { tabName: TabName.INFORMATION, length: false },
    {
      tabName: TabName.QUESTION_AND_ANSWER,
      length: quotationDetails?.questions_count,
      badgeCount: quotationDetails?.unseen_questions_count,
    },
  ];

  const [query, setQuery] = useQuery();
  const selectedTab = query?.tab || 0;
  const initiallyOpenQAId = query?.qa;

  const setSelectedTab = useCallback((_event, index) => {
    setQuery({ tab: index });
  }, []);

  const handleCloseSupplierQuoteView = () => {
    history.push(frontendUrl.supplierQuotes);
    setIsEditQuotationMode(false);
  };
  const [isLoading, setIsLoading] = useState(true);
  const [isInitialLoadDone, setIsInitialLoadDone] = useState(false);

  // match the url and get the active quotation id
  const match = matchPath(location.pathname, {
    path: `${frontendUrl.reviseQuotation}/:id`,
  });

  let activeQuotationIdFromUrl;
  if (match && match.params.id) {
    activeQuotationIdFromUrl = match.params.id;
  }
  const activeQuotationId = activeQuotationIdFromUrl;

  //keep the edit quotation dialog open after refresh page
  useEffect(() => {
    if (
      location.pathname.match(`${frontendUrl.reviseQuotation}/${activeQuotationId}`) &&
      isEditQuotationDialogOpen
    ) {
      handleOpenModal(true, "isEditQuotationDialogOpen");
    }
  }, []);

  useEffect(() => {
    getQuotationDetails(profile.token, params.id, isEditQuotationDialogOpen);
    setIsInitialLoadDone(true);
  }, [params.id]);

  useEffect(() => {
    if (!isInitialLoadDone) {
      setIsLoading(true);
    } else if (isQuotationDetailsLoading || isEditQuotationLoading) {
      setIsLoading(true);
    } else if (quotationDetails !== null) {
      setIsLoading(false);
    }
  }, [quotationDetails, isQuotationDetailsLoading]);

  return (
    <>
      {isLoading ? (
        <Grid container direction="column" justifyContent="center" alignItems="center">
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : (
        <section className="c-supplier-quotation-view">
          <AppBar elevation={0} position="static" className="c-app-bar">
            <Box className="c-app-bar--back-button">
              <BackButton onClick={handleCloseSupplierQuoteView}>{t("mySubmission")}</BackButton>
            </Box>
          </AppBar>
          {/* Tabs */}
          <Grid item xs={12}>
            <Grid item xs={7}>
              <TabsView
                tabItems={tabItems}
                tabHandleChange={setSelectedTab}
                tabValue={selectedTab}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider classes={{ root: classes.divider }} light />
            </Grid>
          </Grid>
          <Grid container xs={12}>
            <TabPanel value={selectedTab} index={0}>
              <RfqsDetailsSummary
                startMessage={t("submittedOn")}
                endMessage={t("remainingBeforeTheEndOfRequestForQuotations")}
                quotation={quotationDetails}
                showChart={false}
                dateToDisplay={quotationDetails?.lead_time}
                handleOpenModal={handleOpenModal}
                subText={t("leadTime")}
                showBackButton={false}
                isSeller={true}
                attachments={quotationDetails?.attachments}
              />
            </TabPanel>
            <TabPanel value={selectedTab} index={1}>
              <QuestionsAnswers
                rfqId={quotationDetails?.rfq}
                initiallyOpenQAId={initiallyOpenQAId}
              />
            </TabPanel>
          </Grid>
          <EditQuotationDialog open={isEditQuotationDialogOpen} />
        </section>
      )}
    </>
  );
};
