import { put, takeLatest } from "redux-saga/effects";
import { safeCall } from "@next/redux/safeCall";
import { analyticsService } from "../services/analytics-services";
import { analyticsActions } from "./slices";
import {
  FetchOrdersChartInput,
  FetchOrdersReportInput,
  FetchRequestsReportInput,
  SupplierReport,
} from "./types";
import { PayloadAction } from "@reduxjs/toolkit";
import { downloadFileNext } from "@next/utils/fileUtils";

function* handleFetchRequestsReportRequest({ payload }: PayloadAction<FetchRequestsReportInput>) {
  const { response, error } = yield safeCall(analyticsService.fetchRequestReport, payload);

  if (error) {
    yield put(
      analyticsActions.fetchRequestsReportFailure(
        new Error("Something went wrong. Please try again.")
      )
    );
  } else {
    yield put(analyticsActions.fetchRequestsReportSuccess(response.data));
  }
}

function* handleFetchOrdersReportRequest({ payload }: PayloadAction<FetchOrdersReportInput>) {
  const { response, error } = yield safeCall(analyticsService.fetchOrdersReport, payload);

  if (error) {
    yield put(analyticsActions.fetchOrdersReportFailure(error));
  } else {
    yield put(analyticsActions.fetchOrdersReportSuccess(response.data));
  }
}

function* handleFetchOrdersVolumeChartRequest({ payload }: PayloadAction<FetchOrdersChartInput>) {
  const { response, error } = yield safeCall(analyticsService.fetchOrdersChart, payload);

  if (error) {
    yield put(analyticsActions.fetchOrdersVolumeChartFailure(error));
  } else {
    yield put(analyticsActions.fetchOrdersVolumeChartSuccess(response.data.charts[0]));
  }
}

function* handleFetchOrdersExpectedReceiptValueChartRequest({
  payload,
}: PayloadAction<FetchOrdersChartInput>) {
  const { response, error } = yield safeCall(analyticsService.fetchOrdersChart, payload);

  if (error) {
    yield put(analyticsActions.fetchOrdersExpectedReceiptValueChartFailure(error));
  } else {
    yield put(
      analyticsActions.fetchOrdersExpectedReceiptValueChartSuccess(response.data.charts[0])
    );
  }
}

function* handleFetchOrdersOnTimeDeliveryChartRequest({
  payload,
}: PayloadAction<FetchOrdersChartInput>) {
  const { response, error } = yield safeCall(analyticsService.fetchOrdersChart, payload);

  if (error) {
    yield put(analyticsActions.fetchOrdersOnTimeDeliveryChartFailure(error));
  } else {
    yield put(analyticsActions.fetchOrdersOnTimeDeliveryChartSuccess(response.data.charts[0]));
  }
}

function* handleFetchOrdersItemLatenessChartRequest({
  payload,
}: PayloadAction<FetchOrdersChartInput>) {
  const { response, error } = yield safeCall(analyticsService.fetchOrdersChart, payload);

  if (error) {
    yield put(analyticsActions.fetchOrdersItemLatenessChartFailure(error));
  } else {
    yield put(analyticsActions.fetchOrdersItemLatenessChartSuccess(response.data.charts[0]));
  }
}

function* handleExportPOAnalyticsAsExcelRequest({
  payload,
}: PayloadAction<FetchOrdersReportInput>) {
  const { response, error } = yield safeCall(analyticsService.exportPOAnalyticsAsExcel, payload);

  if (error) {
    yield put(analyticsActions.exportPOAnalyticsAsExcelFailure(error));
  } else {
    downloadFileNext(response.data, `PO_Analytics_${new Date().toISOString()}.xlsx`);
  }
}

function* handleFetchSuppliersReportRequest() {
  const { response, error } = yield safeCall(analyticsService.fetchSupplierReport);

  return yield put(analyticsActions.fetchSupplierReportSuccess(mockSupplierReportData));

  if (error) {
    yield put(analyticsActions.fetchSupplierReportFailure(error));
  } else {
    yield put(analyticsActions.fetchSupplierReportSuccess(response.data));
  }
}

export function* analyticsSaga() {
  yield takeLatest(analyticsActions.fetchRequestsReportRequest, handleFetchRequestsReportRequest);

  yield takeLatest(analyticsActions.fetchOrdersReportRequest, handleFetchOrdersReportRequest);

  yield takeLatest(
    analyticsActions.fetchOrdersVolumeChartRequest,
    handleFetchOrdersVolumeChartRequest
  );

  yield takeLatest(
    analyticsActions.fetchOrdersExpectedReceiptValueChartRequest,
    handleFetchOrdersExpectedReceiptValueChartRequest
  );

  yield takeLatest(
    analyticsActions.fetchOrdersExpectedReceiptValueChartRequest,
    handleFetchOrdersExpectedReceiptValueChartRequest
  );

  yield takeLatest(
    analyticsActions.fetchOrdersOnTimeDeliveryChartRequest,
    handleFetchOrdersOnTimeDeliveryChartRequest
  );

  yield takeLatest(
    analyticsActions.fetchOrdersItemLatenessChartRequest,
    handleFetchOrdersItemLatenessChartRequest
  );

  yield takeLatest(
    analyticsActions.exportPOAnalyticsAsExcelRequest,
    handleExportPOAnalyticsAsExcelRequest
  );

  yield takeLatest(analyticsActions.fetchSupplierReportRequest, handleFetchSuppliersReportRequest);
}

const mockSupplierReportData: SupplierReport = {
  active_suppliers: 100,
  total_spent: 36300000,
  charts: [
    {
      id: "spending_per_supplier",
      x_axis: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "N", "M"],
      y_axes: [
        {
          id: "total_value",
          values: [
            9000000, 7500000, 6500000, 5500000, 4000000, 2500000, 2400000, 2350000, 2350000,
            2340000, 2330000, 1000000, 300000, 100000,
          ],
        },
        {
          id: "cumulative_value",
          values: [
            10500000, 8000000, 7500000, 5500000, 4000000, 2500000, 2400000, 2550000, 2350000,
            2340000, 2330000, 1000000, 300000, 100000,
          ],
        },
      ],
    },
  ],
};
