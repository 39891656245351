import { Box } from "@material-ui/core";
import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import { Menu, alpha } from "@mui/material";
import { CopiedMenuItem } from "@next/components/copied-menu-item";
import { FetchRemovedAddedSpecsOfScopeResponse } from "@next/modules/vendor-management/redux";
import { colorOptions } from "@next/utils/constantUtils";
import React, { FC, MouseEventHandler, useState } from "react";
import { StyledFlatButton } from "./scope-form.styled";
import { CenteredBox } from "@next/components/centered-box";

type Props = {
  isLoading: boolean;
  onLoadMore: () => void;
  data?: FetchRemovedAddedSpecsOfScopeResponse;
  buttonProps: Partial<LoadingButtonProps>;
};

export const ScopeFormSpecsListBtn: FC<Props> = ({ data, isLoading, onLoadMore, buttonProps }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (data) {
    return (
      <>
        <Box onClick={handleClick} pb={"12px"}>
          <StyledFlatButton
            color="primary"
            variant="text"
            onClick={handleClick}
            loading={isLoading}
            sx={{ background: alpha(colorOptions.royalBlue, 0.1) }}
            {...buttonProps}
          />
        </Box>
        {data.count > 0 && (
          <Menu anchorEl={anchorEl} open={open} onClose={handleClose} sx={{ maxHeight: "400px" }}>
            {data.results.map((item) => (
              <CopiedMenuItem key={item.specification_id} label={item.name} />
            ))}
            {data.next && (
              <CenteredBox p={"12px"} py={0}>
                <LoadingButton
                  variant="text"
                  size="large"
                  fullWidth
                  loading={isLoading}
                  onClick={onLoadMore}
                >
                  Load more
                </LoadingButton>
              </CenteredBox>
            )}
          </Menu>
        )}
      </>
    );
  }
  return null;
};
