import { Box, Button, Typography, styled } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { LoadingButton } from "@mui/lab";

export const StyledButton = styled(Button)({
  height: "40px",
  borderRadius: "8px",
});

export const StyledFlatButton = styled(LoadingButton)({
  height: "32px",
  borderRadius: "14px",
  fontWeight: "normal",
});

export const StyledAddIcon = styled(AddIcon)({
  width: "24px",
  height: "24px",
});

export const StyledTypography = styled(Typography)({
  lineHeight: "20px",
  letterSpacing: "0.25px",
  fontFamily: "Roboto",
});

export const StyledInnerBox = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  gap: "16px",
  alignItems: "center",
  marginTop: "-16px",
});

export const StyledTableBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
});

export const StyledFormFieldBox = styled(Box)({
  width: "48%",
  display: "flex",
});

export const StyledBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
});

export const StyledRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  gap: "12px",
  overflow: "hidden",
});

export const StyledTableWrapper = styled(Box)(() => ({
  margin: "0",
  overflowX: "auto",
}));
