import { alpha } from "@mui/material";
import { vendorManagementActions } from "@next/modules/vendor-management/redux";
import { selectAddedSpecsOfScope } from "@next/modules/vendor-management/redux/selectors";
import { colorOptions } from "@next/utils/constantUtils";
import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ScopeFormSpecsListBtn } from "./scope-form-specs-list-btn";

export const ScopeFormAddedSpecsBtn: FC = () => {
  const { data, isLoading } = useSelector(selectAddedSpecsOfScope);
  const dispatch = useDispatch();

  return (
    <ScopeFormSpecsListBtn
      isLoading={isLoading}
      data={data}
      onLoadMore={() =>
        dispatch(vendorManagementActions.fetchAddedSpecsOfScopeRequest({ next: data.next }))
      }
      buttonProps={{
        children: `${data?.count || 0} spec(s) added from initial scope`,
        color: "primary",
        sx: { background: alpha(colorOptions.royalBlue, 0.1) },
      }}
    />
  );
};
